import React from 'react'
import Plus1 from './PortfolioAssets/Plus 1.png'
import Plus2 from './PortfolioAssets/Plus 2.png'
import Plus3 from './PortfolioAssets/Plus 3.png'
import Microsoft1 from './PortfolioAssets/Microsoft 1.png'
import Microsoft2 from './PortfolioAssets/Microsoft 2.png'
import Microsoft3 from './PortfolioAssets/Microsoft 3.png'
import Concur1 from './PortfolioAssets/Concur 1.png'
import Concur2 from './PortfolioAssets/Concur 2.png'
import Concur3 from './PortfolioAssets/Concur 3.png'

const descriptions = [
    {
        title: 'Plus',
        description: `I worked at Plus for one year ending May 2023. During this time, I worked on the web app among other things, creating clean user experiences that I'm really proud of. I had the priviledge of working with some incredible people to produce these experiences.`,
        images: [Plus1, Plus2, Plus3]
    },
    {
        title: 'Microsoft',
        description: 'I worked at Microsoft Teams for two and a half years ending April 2022. I worked on the Live Transcription feature in Teams meetings.',
        images: [Microsoft1, Microsoft2, Microsoft3]
    },
    {
        title: 'SAP Concur',
        description: 'I worked at Concur for two and a half years ending October 2019. I was on a team that first shipped Concur Locate, then worked on the React overhaul of Concur Expense.',
        images: [Concur1, Concur2, Concur3]
    },
]

export const Portfolio = ({showImage}) => {
    const imageClass = "hidden border border-[#BFBFBF] transition ease-in-out delay-150 hover:-translate-y-1 hover:cursor-pointer hover:scale-110 duration-300 w-full m-1 md:flex items-center"
    const textClass = "h-full w-full flex text-base text-center p-16 items-center snap-center"

    return (
        <div className="h-full flex flex-col overscroll-contain overflow-y-auto items-center bg-[#F7F7F7] divide-y ">
            {descriptions.map((element, i) => {
                return (
                    <div className='h-full w-full'>
                        <div className='flex flex-row items-center h-full w-full overflow-x-auto p-8 hover:bg-[#E5E5E5] transition ease-in-out delay-150 duration-300'>
                            {(i % 2 === 0 ? 
                                    (<>
                                        <div className={imageClass} onClick={() => showImage(element.images[0])}>
                                            <img src={element.images[0]} alt="" />
                                        </div>
                                        <div className={textClass}>
                                            {element.description}
                                        </div>
                                        <div className={imageClass} onClick={() => showImage(element.images[1])}>
                                            <img src={element.images[1]} alt="" />
                                        </div>
                                        <div className={imageClass} onClick={() => showImage(element.images[2])}>
                                            <img src={element.images[2]} alt="" />
                                        </div>
                                    </>)
                                :
                                    (<>
                                        <div className={imageClass} onClick={() => showImage(element.images[0])}>
                                            <img src={element.images[0]} alt="" />
                                        </div>
                                        <div className={imageClass} onClick={() => showImage(element.images[1])}>
                                            <img src={element.images[1]} alt="" />
                                        </div>
                                        <div className={textClass}>
                                            {element.description}
                                        </div>
                                        <div className={imageClass} onClick={() => showImage(element.images[2])}>
                                            <img src={element.images[2]} alt="" />
                                        </div>
                                    </>)
                                )
                            }
                        </div>
                    </div>
                )
            })}
        </div>
    )
}