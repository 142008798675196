import { useState, useCallback } from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import { Portfolio } from './Portfolio';

function App() {
  return (
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
  );
}

function Home() {
  const [showModal, setShowModal] = useState(false)
  const [currentImage, setCurrentImage] = useState('')

  const showImage = useCallback((imageUrl) => {
      setCurrentImage(imageUrl)
      setShowModal(true)
  }, [])

  return (
    <div className="flex flex-col justify-between text-[#0D0D0D] font-serif items-center bg-[#F2F2F2] h-screen w-screen relative">
      <div className="p-8 flex flex-col gap-8 text-center items-center justify-center w-full border-b">
        <div className="text-[2rem] font-semibold">
          I'm a product engineer specializing in business solutions.
        </div>
        <div className="flex flex-row text-[1rem] gap-4">
          <a href='mailto:mail@aravind.sh'>
            <p>email</p>
          </a>
          <a href='https://linkedin.com/in/aravind-parasurama/'>
            <p>linkedin</p>
          </a>
          <a href='https://github.com/ntarctica'>
            <p>github</p>
          </a>
        </div>
      </div>
      <Portfolio showImage={showImage} />
      {showModal && 
        <div className="absolute h-full w-full inset-0 flex flex-col gap-8 items-center justify-center" onClick={() => setShowModal(false)}>
            <div className="relative overflow-scroll flex items-center">
                <img src={currentImage} alt='' className='sm:h-4/5 aspect-auto border-2 border-[#BFBFBF] p-2 bg-[#404040]' />
            </div>
        </div>
      }
      <div className="flex flex-row justify-between w-screen p-8 border-t">
        <div className="flex flex-row gap-4"><div>Aravind Parasurama.</div><div>New York, New York.</div></div>
        <div>© {new Date().getFullYear()}</div>
      </div>
    </div>
  );
}

export default App;
